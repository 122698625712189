export const SiteMixin = {
    methods: {
        site_name(site) {
            let siteObject = site;
            if (siteObject === null || siteObject === undefined) return "Non défini";
            let siteText = siteObject.name;
            if (siteObject.parent !== null) {
                siteText += `, ${siteObject.parent}`;
            }
            if (siteObject.top !== null) {
                siteText += `, ${siteObject.top}`;
            }
            return siteText;
        },
    }
}